.home-component {
  width: 100%;
  height: 100%;
  background: radial-gradient(#022B87 0%, #002266 100%);
}
.home-component .home-main-component {
  width: 1075px;
  height: 546px;
  position: relative;
  margin: 89px auto;
}
.home-component .home-main-component img {
  display: block;
  width: 100%;
}
.home-component .home-main-component .map-compontents {
  position: relative;
  cursor: pointer;
}
.home-component .home-main-component .map-compontents .map-entr-main {
  width: 100%;
  position: absolute;
  left: 50%;
  display: block;
}
.home-component .home-main-component .map-compontents img.bg {
  display: block;
}
.home-component .home-main-component .map-compontents img.bg-select {
  display: none;
}
.home-component .home-main-component .map-compontents:hover img.bg {
  display: none;
}
.home-component .home-main-component .map-compontents:hover img.bg-select {
  display: block;
}
.home-component .home-main-component .nft-entr {
  width: 24.7%;
  height: 44.1%;
  position: absolute;
  top: 55.8%;
  left: 0;
  z-index: 180;
}
.home-component .home-main-component .nft-entr .map-entr-main {
  top: 36%;
  width: 61px;
  margin-left: -30px;
}
.home-component .home-main-component .dividents-1-entr {
  width: 29.1%;
  height: 78.5%;
  position: absolute;
  top: 0;
  left: 11.1%;
  z-index: 160;
}
.home-component .home-main-component .dividents-1-entr .map-entr-main {
  top: 49%;
  width: 70px;
  left: 60%;
  margin-left: -35px;
}
.home-component .home-main-component .mining-entr {
  width: 40.8%;
  height: 83.1%;
  position: absolute;
  top: 13.2%;
  left: 32.1%;
  z-index: 200;
}
.home-component .home-main-component .mining-entr .map-entr-main {
  top: 30%;
  width: 70px;
  left: 47%;
  margin-left: -20px;
}
.home-component .home-main-component .mining-entr .map-entr-main img.stake-entr-en {
  width: 70%;
}
.home-component .home-main-component .govern-entr {
  width: 32.3%;
  height: 61.2%;
  position: absolute;
  top: 38.1%;
  left: 67.7%;
  z-index: 140;
}
.home-component .home-main-component .govern-entr .map-entr-main {
  top: 30%;
  width: 40px;
  left: 47%;
  margin-left: -20px;
}
.home-component .home-main-component .govern-entr .map-entr-main img.govern-entr-en {
  width: 200%;
}
.home-component .home-main-component .promoter-entr {
  width: 22.4%;
  height: 28.2%;
  position: absolute;
  top: 9.9%;
  left: 76.3%;
  z-index: 120;
}
.home-component .home-main-component .promoter-entr .map-entr-main {
  top: 28%;
  width: 45px;
  left: 66%;
  margin-left: -20px;
}
.home-component .home-main-component .promoter-entr .map-entr-main img.promoter-entr-en {
  width: 120%;
}
.right-nav {
  width: 125px;
  position: fixed;
}
.right-nav img {
  transition: all 0.5s;
}
.right-nav .right-nav-text {
  color: #fff;
  font-size: 26px;
  text-align: center;
  margin-top: 15px;
}
.right-nav:hover img {
  transform: rotate(30deg);
}
.right-nav.blind-box-nav {
  right: 65px;
  top: 213px;
  z-index: 300;
  cursor: pointer;
}
.right-nav.email-nav {
  right: 65px;
  top: 439px;
  z-index: 300;
  cursor: pointer;
}
.right-nav .right-nav-icon {
  width: 100%;
  display: block;
}
.bottom-nav {
  width: 450px;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.7);
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -225px;
  overflow: hidden;
  z-index: 300;
}
.bottom-nav .nav-li {
  width: 33.33%;
  height: 100%;
  cursor: pointer;
}
.bottom-nav .nav-li:hover {
  background: #F2F2F2;
}
.bottom-nav .nav-li .nav-icon {
  width: 45px;
  display: block;
  margin: 22px auto 0;
}
.bottom-nav .nav-li .nav-text {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #2C2C2C;
}
