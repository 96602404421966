.blind-box-component {
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  min-height: 100%;
  position: relative;
}
.tick-mask {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.tick-mask .count-down-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}
.tick-mask .count-down-container .count-down-num {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
}
.tick-mask .count-down-container .count-down-symbol {
  width: 65px;
  height: 65px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
.activity-desc-icon {
  position: absolute;
  width: 140px;
  top: -30px;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  cursor: pointer;
}
.activity-desc-icon img {
  width: 100px;
  display: block;
  animation: animswing 5s ease infinite;
}
.activity-desc-icon .text {
  font-size: 14px;
  font-weight: 600;
  color: #AD6328;
  margin-left: 7px;
  margin-top: 10px;
  line-height: 15px;
  letter-spacing: 1px;
  word-break: break-all;
}
.activity-desc-icon .text:nth-child(1) {
  margin-top: -5px;
}
.activity-desc-en-icon {
  position: absolute;
  width: 198px;
  height: 109px;
  top: -30px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../static/images/fivefu/activity-desc-en.png') no-repeat center center;
  background-size: 100% 100%;
  animation: animswing 5s ease infinite;
  cursor: pointer;
}
.activity-desc-en-icon .text {
  font-size: 14px;
  font-weight: 600;
  color: #AD6328;
  margin-left: 7px;
  margin-top: -10px;
  line-height: 20px;
}
.title-icon {
  height: 66px;
  margin: 52px auto 0;
  display: block;
}
.title-1 {
  width: 100%;
  height: 23px;
  font-size: 18px;
  font-weight: 400;
  color: #FBC45D;
  margin-top: 32px;
  text-align: center;
}
.blind-box-coutainer {
  width: 874px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.blind-box-main {
  width: 253px;
  height: 253px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.blind-box-main img {
  width: 100%;
  display: block;
}
.blind-box-main .box-cover {
  width: 100px;
  height: 150px;
  position: absolute;
  right: 36px;
  bottom: 29px;
}
.open-box-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 100;
}
.open-box-mask .title-icon-2 {
  width: 381px;
  height: 66px;
  margin: 85px auto 0;
  display: block;
}
.open-box-mask .photo-frame-main {
  width: 405px;
  height: 404px;
  margin: 125px auto 0;
  display: block;
  position: relative;
}
.open-box-mask .photo-frame-main .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 100;
  cursor: pointer;
}
.open-box-mask .photo-frame-main .photo-frame-img {
  width: 405px;
  height: 404px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.open-box-mask .photo-frame-main .nft-img {
  width: 165px;
  height: 203px;
  position: absolute;
  left: 118px;
  top: 107px;
}
.open-box-mask .open-box-content {
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 24px;
  font-weight: 400;
  color: #FDFDFD;
  margin-top: 56px;
  text-align: center;
}
.fcffe84f {
  color: #FFE84F;
}
.fcffd483 {
  color: #ffd483;
  font-weight: bold;
}
.full-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
@keyframes animswing {
  25% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
}
