.mask-wallet {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 500;
}
.mask-wallet .connect-wallet-tip-container {
  width: 340px;
  background-color: #fff;
  position: absolute;
  top: 15%;
  left: 50%;
  margin-left: -170px;
  border-radius: 10px;
  overflow: hidden;
}
.mask-wallet .connect-wallet-tip-container .title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: left;
  text-indent: 1em;
  position: relative;
}
.mask-wallet .connect-wallet-tip-container .title .close-btn {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 16px;
  top: 16px;
}
.mask-wallet .wallet-tip-container {
  width: 424px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px 0px #215554, 0px 2px 0px 0px rgba(255, 255, 255, 0.5), 2px 0px 0px 0px rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -212px;
  padding-left: 34px;
  padding-right: 34px;
  padding-bottom: 70px;
  border-radius: 10px;
}
.mask-wallet .wallet-tip-container .content {
  width: 100%;
  font-size: 16px;
  color: #000000;
  line-height: 16px;
  text-align: left;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.mask-wallet .wallet-tip-container .content a:hover {
  text-decoration: underline;
}
.WalletLinkConnector-container {
  width: 340px;
  padding: 6px;
  background-color: #f7f8fa;
  padding: 20px;
  z-index: 1000;
}
.WalletLinkConnector-container .WalletLink-item {
  width: 100%;
  height: 60px;
  border-radius: 12px;
  border: 1px solid #edeef2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
  position: relative;
}
.WalletLinkConnector-container .WalletLink-item:hover {
  border: 1px solid #92d50c;
}
.WalletLinkConnector-container .WalletLink-item:nth-child(1) {
  margin-top: 0;
}
.WalletLinkConnector-container .WalletLink-item .connect-state {
  height: 8px;
  width: 8px;
  margin-right: 8px;
  background-color: #27ae60;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  margin-left: 8px;
}
.WalletLinkConnector-container .WalletLink-item .item-con {
  height: 32px;
  line-height: 30px;
  text-align: center;
  color: #3E3E3E;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
  margin-left: 30px;
}
.WalletLinkConnector-container .WalletLink-item .item-icon {
  width: 35px;
  height: 35px;
  margin-right: 20px;
}
.WalletLinkConnector-container .WalletLink-item .item-icon img {
  width: 100%;
  height: 100%;
  display: block;
}
.account-container .loginIn {
  width: 40px;
  height: 40px;
  display: block;
  margin-right: 13px;
  margin-top: 1px;
}
.account-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
}
.account-mask .account-container {
  width: 440px;
  height: 468px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -234px;
  margin-left: -220px;
  background-color: #fff;
}
.account-mask .account-container .account-container-title {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  margin-top: 20px;
  text-align: center;
  position: relative;
}
.account-mask .account-container .account-container-title .close-btn {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 0;
  cursor: pointer;
}
.account-mask .account-container .logo {
  width: 46px;
  height: 60px;
  margin: 52px auto 0;
  display: block;
}
.account-mask .account-container .amount {
  width: 100%;
  height: 42px;
  font-size: 30px;
  font-weight: 600;
  color: #F86200;
  line-height: 42px;
  margin-top: 40px;
  text-align: center;
}
.account-mask .account-container .tit1 {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  text-align: center;
  margin-top: 2px;
}
.account-mask .account-container .account-mask-btn {
  width: 270px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
}
.account-mask .account-container .account-mask-btn-1 {
  margin: 48px auto 0;
  background: #11A058;
  cursor: pointer;
}
.account-mask .account-container .account-mask-btn-1:hover {
  opacity: 0.5;
}
.account-mask .account-container .account-mask-btn-2 {
  margin: 30px auto 0;
  background: #EDBA0E;
  cursor: pointer;
}
.account-mask .account-container .account-mask-btn-2:hover {
  opacity: 0.5;
}
.WalletLink-main {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.WalletLink-main .nologin-icon {
  height: 100%;
}
.WalletLink-main .WalletLink-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.WalletLink-main .WalletLink-main-container .item-text {
  width: 121px;
  border-radius: 4px;
  border: 2px solid #3E3E3E;
  text-align: center;
  color: #3E3E3E;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}
.WalletLink-main .WalletLink-main-container .item-text:hover {
  background-color: #00A7FF;
}
