.casting-component {
  width: 100%;
  min-height: 100%;
  position: relative;
}
.casting-success-tips {
  width: 447px;
  height: 471px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px 0px #215554, 0px 2px 0px 0px rgba(255, 255, 255, 0.5), 2px 0px 0px 0px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -223px;
  margin-top: -236px;
}
.casting-success-tips .close-btn {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.casting-success-tips .title {
  width: 100%;
  height: 24px;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 24px;
  margin-top: 53px;
  text-align: center;
}
.casting-success-tips .nft-pic {
  width: 202px;
  height: 202px;
  display: block;
  margin: 28px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.casting-success-tips .nft-pic img {
  display: block;
  width: 100%;
}
.casting-success-tips .ok-btn {
  width: 240px;
  height: 50px;
  background: #FFD86E;
  border-radius: 4px;
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.casting-success-tips .router-nft {
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #4996BD;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
.casting-top-main {
  width: 100%;
  margin-top: 34px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px 0px #DBDBDB, 0px -1px 0px 0px #DBDBDB;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.casting-top-main .casting-btn {
  width: 137px;
  height: 38px;
  line-height: 38px;
  background: #4996BD;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
}
.casting-top-main .casting-btn:hover {
  opacity: 0.5;
}
.table-compontent {
  width: 100%;
  margin-top: 8px;
}
.table-compontent thead tr td {
  height: 60px;
  background: #F8F8F8;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  line-height: 60px;
}
.table-compontent thead tr td:nth-child(1) {
  width: 25%;
  padding-left: 20px;
}
.table-compontent thead tr td:nth-child(2) {
  width: 25%;
}
.table-compontent thead tr td:nth-child(3) {
  width: 30%;
}
.table-compontent thead tr td:nth-child(4) {
  width: 20%;
}
.table-compontent tbody tr td {
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
}
.table-compontent tbody tr td .icon {
  width: 26px;
  height: 26px;
  margin-right: 12px;
}
.table-compontent tbody tr td:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}
.table-compontent tbody tr:nth-child(even) {
  background: #F8F8F8;
}
.table-loading-main {
  width: 100%;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
}
