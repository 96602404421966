.referral-component {
  width: 100%;
}
.referral-component .center-component {
  width: 1200px;
  margin: 38px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 30px;
}
.referral-component .center-component .left-component {
  width: 704px;
}
.referral-component .center-component .left-component .invitation-rule-main {
  width: 704px;
  height: 581px;
  background: url(../../static/images/referral/invitation-rule-bg.png) no-repeat left top;
  background-size: 100% 100%;
  border-radius: 20px;
  padding-top: 1px;
  position: relative;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-bg-text {
  position: absolute;
  top: 76px;
  left: 104px;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container {
  width: 660px;
  padding-top: 343px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main {
  width: 320px;
  padding-top: 13px;
  height: 215px;
  position: relative;
  background-color: #fff;
  border-radius: 6px;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .title-icon-1 {
  width: 53px;
  position: absolute;
  top: -20px;
  left: 15px;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .title-icon-2 {
  width: 48px;
  position: absolute;
  top: -20px;
  left: 21px;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .title {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #CF2584;
  text-align: center;
  line-height: 22px;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .invitation-link-main {
  width: 281px;
  height: 40px;
  font-size: 14px;
  color: #666;
  line-height: 20px;
  margin: 46px auto 0;
  word-wrap: break-word;
  word-break: normal;
  text-align: left;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .tagging {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  text-align: center;
  margin-top: 16px;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .amount-main {
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 28px;
  color: #F86200;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .amount-main .unit {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  margin-left: 5px;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .withdrawal-btn {
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #802BFF;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  border-radius: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 26px auto 0;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .withdrawal-btn:hover {
  opacity: 0.5;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .copy-btn {
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #802BFF;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
  margin: 28px auto 0;
}
.referral-component .center-component .left-component .invitation-rule-main .referral-info-container .referral-info-main .copy-btn:hover {
  opacity: 0.5;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context {
  margin-top: 343px;
  width: 100%;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents {
  width: 654px;
  margin: 57px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents .rule-main {
  width: 200px;
  height: 205px;
  background: #FFFFFF;
  border-radius: 6px;
  position: relative;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents .rule-main .rule-main-icon-1 {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents .rule-main .rule-main-icon-2 {
  width: 74px;
  height: 65px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -32px;
  margin-left: -37px;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents .rule-main .rule-main-icon-3 {
  width: 56px;
  height: 65px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents .rule-main .rule-main-content {
  width: 140px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  color: #CC2588;
  line-height: 20px;
  margin: 47px auto 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents .rule-main .explain-1 {
  margin: 23px auto 0;
  width: 110px;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents .rule-main .explain-2 {
  margin: 23px auto 0;
  width: 75px;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.referral-component .center-component .left-component .invitation-rule-main .invitation-rule-context .rule-compontents .rule-main .explain-3 {
  margin: 23px auto 0;
  width: 160px;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.referral-component .center-component .left-component .referral-container {
  padding-bottom: 10px;
  margin-top: 30px;
}
.referral-component .center-component .left-component .referral-container .referral-main {
  width: 100%;
  padding-top: 28px;
  padding-left: 35px;
  padding-right: 35px;
  background: #11388C;
  padding-bottom: 20px;
  border-radius: 20px;
}
.referral-component .center-component .left-component .referral-container .referral-main .title {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 22px;
}
.referral-component .center-component .left-component .referral-container .referral-main .referral-input-container {
  width: 100%;
  height: 45px;
  line-height: 45px;
  margin-top: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.referral-component .center-component .left-component .referral-container .referral-main .referral-input-container .referral-input {
  width: 350px;
  height: 45px;
  background: #F7F7F7;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-indent: 10px;
  border: none;
  outline: none;
  color: #333;
}
.referral-component .center-component .left-component .referral-container .referral-main .referral-input-container .referral-input.error-referral-input {
  border: 1px solid #ff0000;
}
.referral-component .center-component .left-component .referral-container .referral-main .referral-input-container .referral-submit {
  width: 194px;
  height: 45px;
  background: linear-gradient(180deg, #FFEB79 0%, #FFD244 100%);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #A46007;
  text-align: center;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.referral-component .center-component .left-component .referral-container .referral-main .referral-input-container .referral-submit.disable {
  background: #ccc;
  color: #FFFFFF;
}
.referral-component .center-component .left-component .referral-container .referral-main .referral-input-container .referral-submit:hover.disable {
  opacity: 1;
}
.referral-component .center-component .left-component .referral-container .referral-main .referral-input-container .referral-submit:hover {
  opacity: 0.5;
}
.referral-component .center-component .left-component .referral-container .referral-main .remarks {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  margin-top: 34px;
}
.referral-component .center-component .left-component .referral-container .referral-main .remarks1 {
  width: 100%;
  margin-top: 23px;
}
.referral-component .center-component .left-component .referral-container .referral-main .remarks1 p {
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  color: #6090F8;
}
.referral-component .center-component .left-component .referral-container .account-container {
  width: 100%;
  height: 198px;
  background: #11388C;
  padding-top: 28px;
  border-radius: 20px;
  padding-left: 35px;
  margin-top: 30px;
}
.referral-component .center-component .left-component .referral-container .account-container .title {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 22px;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main {
  width: 33%;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .amount-con {
  width: 100%;
  height: 28px;
  line-height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 28px;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .amount-con.amount-1 {
  color: #FFD244;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .amount-con.amount-2 {
  color: #802BFF;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .amount-con.amount-3 {
  color: #CF2584;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .amount-con .unit {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  margin-left: 5px;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .remarks {
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 18px;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .remarks .tips {
  width: 25px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .remarks .tips:hover .small-prompt-window {
  display: block;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .remarks .tips img {
  width: 18px;
  height: 18px;
  margin-left: 12px;
}
.referral-component .center-component .left-component .referral-container .account-container .account-info-container .account-info-main .remarks .tips .small-prompt-window {
  width: 200px;
  margin-left: -150px;
  background: #FFFFFF;
  position: absolute;
  top: 20px;
  left: 50%;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 9px;
  font-size: 12px;
  color: #000000;
  line-height: 14px;
  display: block;
  z-index: 300;
  padding-bottom: 9px;
  box-shadow: 0px 6px 29px 0px #aaa;
  display: none;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 25px;
  padding-top: 28px;
  background-color: #11388C;
  margin-top: 28px;
  border-radius: 20px;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container .my-rebate-record-title {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 22px;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container .rebate-record-table {
  width: 100%;
  margin-top: 20px;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container .rebate-record-table thead tr {
  border-radius: 6px;
  overflow: hidden;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container .rebate-record-table thead tr td {
  height: 38px;
  line-height: 38px;
  background: #234EAA;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container .rebate-record-table thead tr td:nth-child(1) {
  border-radius: 6px 0 0 6px;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container .rebate-record-table thead tr td:nth-child(3) {
  border-radius: 0 6px 6px 0;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container .rebate-record-table tbody tr td {
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.referral-component .center-component .left-component .referral-container .my-rebate-record-container .rebate-record-table tbody tr:nth-child(even) td {
  background: #EFEFF1;
}
.referral-component .center-component .right-component {
  width: 460px;
}
.referral-component .center-component .right-component .list-component {
  width: 100%;
  height: 581px;
  padding-left: 20px;
  padding-right: 20px;
  background: #11388C;
  border-radius: 20px;
  padding-top: 30px;
}
.referral-component .center-component .right-component .list-component.list-component-bottom {
  height: 760px;
}
.referral-component .center-component .right-component .list-component .title {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 22px;
  position: relative;
}
.referral-component .center-component .right-component .list-component .title .title-icon-pig {
  width: 48px;
  height: 64px;
  position: absolute;
  bottom: 0;
  right: 32px;
}
.referral-component .center-component .right-component .list-component .list-header {
  width: 100%;
  height: 38px;
  line-height: 38px;
  background: #234EAA;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.referral-component .center-component .right-component .list-component .list-header .header-text-left {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 21px;
}
.referral-component .center-component .right-component .list-component .list-header .header-text-right {
  margin-right: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
.referral-component .center-component .right-component .list-component .list-container {
  width: 100%;
  overflow-y: auto;
}
.referral-component .center-component .right-component .list-component .list-container .list-li-main {
  width: 400px;
  height: 70px;
  background: #FFFEFF;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}
.referral-component .center-component .right-component .list-component .list-container .list-li-main .portrait {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.referral-component .center-component .right-component .list-component .list-container .list-li-main .list-li-content {
  margin-left: 16px;
}
.referral-component .center-component .right-component .list-component .list-container .list-li-main .list-li-content .list-li-title {
  height: 18px;
  font-size: 18px;
  font-weight: normal;
  color: #2C2C2C;
  line-height: 18px;
}
.referral-component .center-component .right-component .list-component .list-container .list-li-main .list-li-content .list-li-addr {
  height: 12px;
  line-height: 12px;
  font-size: 12px;
  font-weight: normal;
  color: #959494;
}
.referral-component .center-component .right-component .list-component .list-container .list-li-main .amount-main {
  margin-left: 48px;
  height: 24px;
  line-height: 24px;
  font-size: 24px;
  font-weight: bold;
  color: #CF2584;
}
.referral-component .center-component .right-component .list-component .list-container .list-li-main .amount-main .unit {
  font-size: 18px;
  color: #959494;
}
.fccf2584 {
  color: #CF2584;
}
.mart23 {
  margin-top: 23px;
}
.mart30 {
  margin-top: 30px;
}
.comesoon-main {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comesoon-main .comesoon-icon-main {
  width: 100%;
}
.comesoon-main .comesoon-icon-main img {
  width: 100px;
  margin: auto;
  display: block;
}
.comesoon-main .comesoon-icon-main p {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #6090F8;
  line-height: 22px;
  text-align: center;
  margin-top: 63px;
}
input::-webkit-input-placeholder {
  color: #999999;
}
input::-moz-placeholder {
  color: #999999;
}
input:-ms-input-placeholder {
  color: #999999;
}
