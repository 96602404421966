.mining-container {
  width: 1100px;
  margin: auto;
}
.dividendsdetails-bg {
  background: #012572;
}
.dividendsdetails-bg .crumbs-bar {
  width: 1200px;
  margin: 0 auto;
  height: 119px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dividendsdetails-bg .crumbs-bar p {
  font-size: 16px;
  color: #FFFFFF;
}
.dividendsdetails-bg .crumbs-bar p:nth-child(1) {
  cursor: pointer;
}
.dividendsdetails-bg .crumbs-bar p:nth-child(2) {
  color: #00F4E4;
}
.dividendsdetails-bg .dividendsdetails-info-container {
  width: 1200px;
  background: #11388C;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 26px;
  margin: 0 auto;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 37px;
  position: relative;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .captain-staking-btn {
  width: 100px;
  height: 32px;
  background: #00F4E4;
  line-height: 32px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #1453A4;
  position: absolute;
  top: 0;
  right: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .captain-staking-btn:hover {
  background-color: #1453A4;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-portrait {
  width: 188px;
  height: 188px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-portrait img {
  display: block;
  width: 100%;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-info-main {
  margin-left: 30px;
  width: 662px;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-info-main .nft-name {
  width: 100%;
  height: 35px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  line-height: 35px;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-info-main .nft-captain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 20px;
  font-size: 14px;
  color: #6090F8;
  line-height: 20px;
  margin-top: 11px;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-info-main .nft-captain .captain-address {
  color: #fff;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-info-main .nft-attribute-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 63px;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-info-main .nft-attribute-group .nft-attribute-item {
  width: 50%;
  height: 17px;
  font-size: 12px;
  font-weight: 600;
  color: #6090F8;
  line-height: 17px;
  margin-top: 12px;
}
.dividendsdetails-bg .dividendsdetails-info-container .dividendsdetails-nft-info .nft-info-main .nft-attribute-group .nft-attribute-item .item-value {
  color: #FFFFFF;
}
.dividendsdetails-bg .dividendsdetails-info-container .captain-info-container {
  width: 100%;
  margin-top: 27px;
  padding-left: 6px;
  border-top: 1px solid #1453A4;
  padding-bottom: 31px;
  padding-top: 27px;
}
.dividendsdetails-bg .dividendsdetails-info-container .captain-info-container .captain-title {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  text-align: left;
}
.dividendsdetails-bg .dividendsdetails-info-container .captain-info-container .captain-attribute-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}
.dividendsdetails-bg .dividendsdetails-info-container .captain-info-container .captain-attribute-group .captain-attribute {
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #6090F8;
  line-height: 20px;
}
.dividendsdetails-bg .dividendsdetails-info-container .captain-info-container .captain-attribute-group .captain-attribute.marl188 {
  margin-left: 188px;
}
.dividendsdetails-bg .dividendsdetails-info-container .captain-info-container .captain-attribute-group .captain-attribute .captain-attribute-value {
  color: #FFD244;
}
.dividendsdetails-bg .dividendsdetails-info-container .captain-info-container .captain-attribute-group .view-btn {
  width: 42px;
  height: 22px;
  border-radius: 2px;
  border: 1px solid #00F4E4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #00F4E4;
  margin-left: 8px;
  cursor: pointer;
}
.dividendsdetails-bg .dividendsdetails-info-container .captain-info-container .captain-attribute-group .view-btn:hover {
  background: #00F4E4;
  color: #fff;
}
.dividendsdetails-bg .dividendsdetails-operation-container {
  width: 1200px;
  margin: 30px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main {
  width: 585px;
  height: 255px;
  background: #11388C;
  border-radius: 20px;
  padding-left: 26px;
  padding-right: 26px;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-title {
  width: 100%;
  height: 20px;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  text-align: left;
  margin-top: 20px;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-name {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #6090F8;
  text-align: center;
  margin-top: 14px;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-con {
  width: 100%;
  height: 33px;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 33px;
  margin-top: 14px;
  text-align: center;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-btn {
  width: 240px;
  height: 40px;
  line-height: 40px;
  background: #00F4E4;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #1453A4;
  text-align: center;
  margin: 28px auto 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-btn:hover {
  opacity: 0.5;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-btn-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-btn-bar .dividendsdetails-operation-btn-left {
  width: 200px;
  height: 40px;
  background: #00F4E4;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #022981;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-btn-bar .dividendsdetails-operation-btn-left.gray {
  background: #ccc;
  color: #fff;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-btn-bar .dividendsdetails-operation-btn-left:hover {
  opacity: 0.5;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-btn-bar .dividendsdetails-operation-btn-right {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #00F4E4;
  border: 1px solid #00F4E4;
  line-height: 40px;
  text-align: center;
  margin-left: 14px;
  cursor: pointer;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-btn-bar .dividendsdetails-operation-btn-right:hover {
  opacity: 0.5;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-remarks {
  width: 100%;
  margin-top: 29px;
  height: 17px;
  font-size: 12px;
  font-weight: 600;
  color: #6090F8;
  line-height: 17px;
  text-align: center;
}
.dividendsdetails-bg .dividendsdetails-operation-container .dividendsdetails-operation-main .dividendsdetails-operation-remarks .remarks-con {
  color: #FFD244;
}
.marl188 {
  margin-left: 188px;
}
.staking-mask-container {
  width: 541px;
  background: linear-gradient(159deg, #11388C 0%, #01246E 100%);
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -289px;
  margin-left: -270.5px;
}
.staking-mask-container .title {
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-indent: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  border-bottom: 1px solid #1453A4;
  position: relative;
}
.staking-mask-container .title .close-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.staking-mask-container .content-container {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}
.staking-mask-container .content-container .btn-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 246px;
  height: 54px;
  background: #1A439C;
  border-radius: 8px;
  padding: 8px;
  margin-top: 17px;
}
.staking-mask-container .content-container .btn-bar .btn {
  width: 115px;
  height: 38px;
  line-height: 38px;
  background: #00F4E4;
  border-radius: 8px;
  font-size: 14px;
  color: #1453A4;
  text-align: center;
  cursor: pointer;
}
.staking-mask-container .content-container .btn-bar .btn.disable {
  background: none;
  color: #6090F8;
}
.staking-mask-container .content-container .container-main-cocos {
  width: 100%;
  min-height: 300px;
  background: #1A439C;
  border-radius: 10px;
  margin-top: 17px;
}
.staking-mask-container .content-container .container-main-cocos .total-power-bar {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-indent: 20px;
  border-bottom: 1px solid #1453A4;
}
.staking-mask-container .content-container .container-main-cocos .total-power-bar .fcfb8126 {
  color: #FFD244;
}
.staking-mask-container .content-container .container-main-cocos .balance-bar {
  width: 410px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 25px auto 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #6090F8;
}
.staking-mask-container .content-container .container-main-cocos .balance-bar .fcfb8126 {
  color: #FFD244;
}
.staking-mask-container .content-container .container-main-cocos .input-bar {
  width: 410px;
  height: 45px;
  line-height: 45px;
  margin: 7px auto 0;
  position: relative;
}
.staking-mask-container .content-container .container-main-cocos .input-bar .input-num {
  width: 100%;
  height: 45px;
  line-height: 45px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-indent: 18px;
}
.staking-mask-container .content-container .container-main-cocos .input-bar .max-btn {
  width: 46px;
  height: 26px;
  line-height: 26px;
  background: #6090F8;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  position: absolute;
  top: 10px;
  right: 16px;
  cursor: pointer;
}
.staking-mask-container .content-container .container-main-cocos .rule-main {
  width: 410px;
  margin: 20px auto 0;
  padding-bottom: 20px;
  color: #6090F8;
}
.staking-mask-container .content-container .container-main-cocos .rule-main p {
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
}
.staking-mask-container .content-container .container-main-cocos .rule-main p:nth-child(1) {
  color: #FFD244;
  font-size: 14px;
}
.staking-mask-container .content-container .container-main-cocos .staking-btn {
  width: 410px;
  height: 45px;
  line-height: 45px;
  background: linear-gradient(180deg, #FFEB79 0%, #FFD244 100%);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #A46007;
  margin: 28px auto 0;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.staking-mask-container .content-container .container-main-cocos .staking-btn:hover {
  opacity: 0.5;
}
.staking-mask-container .content-container .container-main {
  width: 100%;
  height: 394px;
  background: #1A439C;
  border-radius: 10px;
  margin-top: 17px;
}
.staking-mask-container .content-container .container-main .total-power-bar {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-indent: 20px;
  border-bottom: 1px solid #1453A4;
}
.staking-mask-container .content-container .container-main .total-power-bar .fcfb8126 {
  color: #FFD244;
}
.staking-mask-container .content-container .container-main .list-main {
  width: 100%;
  height: 333px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
}
.staking-mask-container .content-container .container-main .list-main:-webkit-scrollbar {
  background: none;
}
.staking-mask-container .content-container .container-main .list-main.loading-status {
  display: flex;
  justify-content: center;
  align-items: center;
}
.staking-mask-container .content-container .container-main .list-main .empty-text {
  font-size: 14px;
  color: #6090F8;
}
.staking-mask-container .content-container .container-main .list-main .to-casting-btn {
  font-size: 14px;
  color: #00F4E4;
  cursor: pointer;
}
.staking-mask-container .content-container .container-main .list-main .to-casting-btn:hover {
  opacity: 0.5;
}
.staking-mask-container .content-container .container-main .list-main .li-main {
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #1453A4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.staking-mask-container .content-container .container-main .list-main .li-main .icon-main {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.staking-mask-container .content-container .container-main .list-main .li-main .icon-main img {
  display: block;
  width: 100%;
}
.staking-mask-container .content-container .container-main .list-main .li-main .nft-info-container {
  margin-left: 17px;
}
.staking-mask-container .content-container .container-main .list-main .li-main .nft-info-container .name {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.staking-mask-container .content-container .container-main .list-main .li-main .nft-info-container .attr {
  width: 100%;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #6090F8;
}
.staking-mask-container .content-container .container-main .list-main .li-main .li-main-btn {
  width: 110px;
  height: 32px;
  line-height: 32px;
  border-radius: 15px;
  position: absolute;
  right: 0;
  top: 37px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #00F4E4;
  border: 1px solid #00F4E4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.staking-mask-container .content-container .container-main .list-main .li-main .li-main-btn:hover {
  background: #00F4E4;
  color: #fff;
}
.mask-container {
  width: 424px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -212px;
  padding-left: 34px;
  padding-right: 34px;
}
.mask-container.sale-mask-container {
  height: 377px;
  margin-top: -188px;
}
.mask-container.send-mask-container {
  height: 317px;
  margin-top: -158px;
  background: linear-gradient(159deg, #11388C 0%, #01246E 100%);
}
.mask-container.getReward-mask-container {
  width: 541px;
  height: 474px;
  background: linear-gradient(159deg, #11388C 0%, #01246E 100%);
  border-radius: 20px;
  margin-top: -270px;
  margin-left: -237px;
  padding-left: 41px;
  padding-right: 40px;
}
.mask-container .content {
  width: 460px;
  height: 66px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6090F8;
  line-height: 22px;
}
.mask-container .mask-input-container {
  width: 100%;
  height: 45px;
  border: 1px solid #000;
  margin-top: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask-container .mask-input-container .mask-input {
  width: 100%;
  height: 43px;
  line-height: 43px;
  text-indent: 14px;
  font-size: 14px;
  color: #999999;
  border: none;
  outline: none;
  padding: 0;
}
.mask-container .mask-input-container .mask-input.isWarming {
  border: 1px solid #ff0000;
}
.mask-container .mask-input-container .unit {
  width: 30px;
  height: 14px;
  font-size: 14px;
  color: #000000;
  line-height: 14px;
  position: absolute;
  top: 14px;
  right: 10px;
}
.mask-container .mask-input-container .max-btn {
  width: 52px;
  height: 28px;
  line-height: 28px;
  background: #ECF0F3;
  position: absolute;
  top: 9px;
  right: 14px;
  text-align: center;
  font-size: 14px;
  color: #2BA8FF;
  cursor: pointer;
}
.mask-container .punishtime-count-down {
  width: 460px;
  height: 102px;
  background: #1A439C;
  border-radius: 19px;
  margin-top: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask-container .punishtime-count-down .punishtime-count-down-item {
  width: 57px;
}
.mask-container .punishtime-count-down .punishtime-count-down-item p:nth-child(1) {
  width: 100%;
  height: 28px;
  font-size: 24px;
  font-family: HelveticaNeue-Bold, HelveticaNeue;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
}
.mask-container .punishtime-count-down .punishtime-count-down-item p:nth-child(2) {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #6090F8;
  line-height: 20px;
  text-align: center;
  margin-top: 5px;
}
.mask-container .punishtime-count-down .punishtime-count-down-symbol {
  width: 18px;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #6090F8;
  line-height: 25px;
}
.mask-container .getReward-confirm-btn {
  width: 410px;
  height: 45px;
  background: linear-gradient(180deg, #FFEB79 0%, #FFD244 100%);
  border-radius: 8px;
  font-size: 14px;
  color: #A46007;
  line-height: 45px;
  text-align: center;
  margin: 55px auto 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask-container .getReward-confirm-btn:hover {
  opacity: 0.5;
}
