.nftDetails-container {
  width: 100%;
  height: 530px;
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nftDetails-container .nftDetails-icon {
  width: 580px;
  height: 470px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nftDetails-container .nftDetails-icon img {
  width: 100%;
  display: block;
}
.nftDetails-container .nftDetails-info {
  width: 518px;
  position: relative;
}
.nftDetails-container .nftDetails-info .disassemble-btn {
  width: 120px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #979797;
  position: absolute;
  top: 0;
  right: 0;
  transition: width 0.5s;
  cursor: pointer;
  overflow: hidden;
}
.nftDetails-container .nftDetails-info .disassemble-btn .disassemble-container {
  height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px;
}
.nftDetails-container .nftDetails-info .disassemble-btn .disassemble-container .disassemble-icon {
  width: 24px;
  height: 24px;
}
.nftDetails-container .nftDetails-info .disassemble-btn .disassemble-container .disassemble-text {
  margin-left: 7px;
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
}
.nftDetails-container .nftDetails-info .title {
  width: 100%;
  height: 28px;
  font-size: 24px;
  color: #000000;
  line-height: 28px;
}
.nftDetails-container .nftDetails-info .isowner {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  margin-top: 3px;
}
.nftDetails-container .nftDetails-info .describe {
  width: 100%;
  height: 143px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  margin-top: 26px;
}
.nftDetails-container .nftDetails-info .btn-group {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nftDetails-container .nftDetails-info .btn-group .btn {
  width: 200px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}
.nftDetails-container .nftDetails-info .btn-group .btn:hover {
  opacity: 0.5;
}
.nftDetails-container .nftDetails-info .btn-group .btn.send-btn {
  border: 1px solid #2BA8FF;
  color: #2BA8FF;
  background: #fff;
  margin-right: 17px;
}
.nftDetails-container .nftDetails-info .btn-group .btn.sale-btn {
  border: 1px solid #2BA8FF;
  color: #fff;
  background: #2BA8FF;
}
.nftDetails-container .nftDetails-info .title1 {
  width: 100%;
  height: 27px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  line-height: 14px;
  margin-top: 42px;
  padding-bottom: 13px;
  border-bottom: 1px solid #DBDBDB;
}
.nftDetails-container .nftDetails-info .attr-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.nftDetails-container .nftDetails-info .attr-group .attr-li {
  width: 50%;
  height: 14px;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 17px;
}
.nftDetails-container .nftDetails-info .attr-group .attr-li .key {
  width: 45%;
  height: 14px;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
}
.nftDetails-container .nftDetails-info .attr-group .attr-li .val {
  width: 55%;
  height: 14px;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
}
.nftDetails-container .nftDetails-info .attr-group .attr-li .val.cursor-addr {
  cursor: pointer;
}
.nftDetails-container .nftDetails-info .attr-group .attr-li .val.cursor-addr:hover {
  text-decoration: underline;
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.mask .mask-container {
  width: 424px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -212px;
  padding-left: 34px;
  padding-right: 34px;
}
.mask .mask-container .close-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.mask .mask-container.sale-mask-container {
  height: 377px;
  margin-top: -188px;
}
.mask .mask-container.send-mask-container {
  height: 317px;
  margin-top: -158px;
}
.mask .mask-container .title {
  width: 100%;
  height: 14px;
  font-size: 14px;
  color: #000000;
  line-height: 14px;
  text-align: left;
}
.mask .mask-container .mask-input-container {
  width: 100%;
  height: 45px;
  border: 1px solid #000;
  margin-top: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask .mask-container .mask-input-container .mask-input {
  width: 100%;
  height: 43px;
  line-height: 43px;
  text-indent: 14px;
  font-size: 14px;
  color: #999999;
  border: none;
  outline: none;
  padding: 0;
}
.mask .mask-container .mask-input-container .mask-input.isWarming {
  border: 1px solid #ff0000;
}
.mask .mask-container .mask-input-container .unit {
  width: 30px;
  height: 14px;
  font-size: 14px;
  color: #000000;
  line-height: 14px;
  position: absolute;
  top: 14px;
  right: 10px;
}
.mask .mask-container .mask-input-container .max-btn {
  width: 52px;
  height: 28px;
  line-height: 28px;
  background: #ECF0F3;
  position: absolute;
  top: 9px;
  right: 14px;
  text-align: center;
  font-size: 14px;
  color: #2BA8FF;
  cursor: pointer;
}
.mask .mask-container .confirm-btn {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: #2BA8FF;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask .mask-container .confirm-btn:hover {
  opacity: 0.5;
}
.mask .mask-disassemble {
  width: 447px;
  height: 446px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px 0px #215554, 0px 2px 0px 0px rgba(255, 255, 255, 0.5), 2px 0px 0px 0px rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -223px;
  margin-top: -223px;
}
.mask .mask-disassemble .close-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.mask .mask-disassemble .disassemble-icon {
  width: 202px;
  height: 202px;
  margin: 65px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask .mask-disassemble .disassemble-icon img {
  width: 100%;
}
.mask .mask-disassemble .disassemble-nft-Amount {
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 22px;
  margin-top: 19px;
  text-align: center;
}
.mask .mask-disassemble .disassemble-nft-Amount .countdown {
  color: #FF567B;
}
.mask .mask-disassemble .disassemble-btn {
  width: 240px;
  height: 50px;
  line-height: 50px;
  background: #FB8126;
  border-radius: 25px;
  margin: 42px auto 0;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask .mask-disassemble .disassemble-btn.disable-btn {
  background: #BDBDBD !important;
}
.mart67 {
  margin-top: 67px;
}
.mart24 {
  margin-top: 24px;
}
.mart73 {
  margin-top: 73px;
}
