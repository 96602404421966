.top-main-compontent {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.top-main-compontent .top-main {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.top-main-compontent .top-main .logo-main {
  display: block;
  margin-top: -3.5px;
}
.top-main-compontent .top-main .logo-main img {
  width: 140px;
  height: 62px;
  display: block;
}
.top-main-compontent .top-main .center-main {
  width: 140px;
  display: block;
  cursor: pointer;
}
.top-main-compontent .top-main .cocos-text {
  margin-left: 20px;
  cursor: pointer;
}
.top-main-compontent .top-main .back-btn {
  position: absolute;
  left: 0;
  top: 20px;
  width: 24px;
  height: 22px;
  cursor: pointer;
}
.top-main-compontent .top-main .account-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.top-main-compontent .top-main .account-btn .change-language {
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: #FFFFFF;
  border: 1px solid #979797;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 17px;
}
