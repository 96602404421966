
@font-face {
    font-family: 'Helvetica';
    src: url(./static/font/Helvetica.ttc);
}
html, body, p, ul, li, ol, div, h1 {
  margin-bottom: 0!important;
  padding: 0;
  box-sizing: border-box!important;
  margin: 0;
  user-select:none;
  font-family: Helvetica;
}
a{
    text-decoration: none;
}
.container-main {
    width: 1150px;
    margin: 0 auto;
    position: relative;
}
.container-center {
    width: 1150px;
    margin: 0 auto;
    position: relative;
}

.bg-component {
    width: 100%;
    background: #F7F7F7;
    min-height: 100%;
}

.display-flex-between {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.blank1{
    width: 100%;
    height: 1px;
}


.display-flex-desktop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
}




@media screen and (min-width: 320px) and (max-width: 359px){ 

    .display-flex-desktop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
    }
} 

@media screen and (min-width: 360px) and (max-width: 374px){ 

    .display-flex-desktop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
    }
} 

@media screen and (min-width: 375px) and (max-width: 385px){ 

    .display-flex-desktop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
    }
} 

@media screen and (min-width: 386px) and (max-width: 392px) { 

    .display-flex-desktop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
    }
} 

@media screen and (min-width: 393px) and (max-width: 400px){ 

    .display-flex-desktop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
    }
} 

@media screen and (min-width: 401px) and (max-width: 414px){ 

    .display-flex-desktop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
    }
} 
/* .homepage_main  */

.fm {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace!important;
}


.weak-hint-main {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    line-height: 50px;
    margin-left: -200px;
    font-size: 1.6rem;
    border-radius: 0.3rem;
    animation: myfirst 3s ease-in-out 0s 1 alternate forwards;
    z-index: 300;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
}
@keyframes myfirst {
    0% {
        top: -10%;
        opacity: 0;
    }
    20% {
        opacity: 1;
        top: 20%;
    }
    80% {
        opacity: 1;
        top: 20%;
    }
    100% {
        top: -10%;
        opacity: 0;
    }
}