.choose-captain-mask {
  width: 641px;
  height: 521px;
  background: linear-gradient(159deg, #11388C 0%, #01246E 100%);
  border-radius: 21px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -320px;
  margin-top: -260px;
  padding-left: 26px;
  padding-right: 30px;
}
.choose-captain-mask .close-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.choose-captain-mask .choose-captain-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.choose-captain-mask .choose-captain-container .captain-nft-icon {
  width: 197px;
  height: 213px;
  background: rgba(0, 110, 184, 0.04);
  border-radius: 0px 0px 18px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-captain-mask .choose-captain-container .captain-nft-icon img {
  display: block;
  width: 100%;
}
.choose-captain-mask .choose-captain-container .choose-operation-main {
  margin-left: 27px;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .choose-operation-main-title {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  text-align: left;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .choose-operation-main-title.mart41 {
  margin-top: 41px;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .choose-operation-main-title.mart22 {
  margin-top: 22px;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container {
  width: 361px;
  height: 41px;
  line-height: 41px;
  border: 1px solid #F2F2F2;
  margin-top: 10px;
  position: relative;
  background-color: #fff;
  text-indent: 1em;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container.border-red {
  border: 1px solid #ff0000;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container .drop-down-list {
  width: 361px;
  height: 243px;
  padding-right: 15px;
  padding-left: 15px;
  background: #fff;
  border-left: 1px solid #F2F2F2;
  border-right: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
  position: absolute;
  left: -1px;
  top: 40px;
  z-index: 10;
  overflow-y: auto;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container .drop-down-list .drop-down-li {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #F2F2F2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container .drop-down-list .drop-down-li:hover {
  opacity: 0.5;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container .drop-down-list .drop-down-li .nft-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container .drop-down-list .drop-down-li .nft-icon img {
  width: 100%;
  display: block;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container .drop-down-list .drop-down-li .nft-name {
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 14px;
  margin-left: 25px;
}
.choose-captain-mask .choose-captain-container .choose-operation-main .drop-down-container .drop-down-list .drop-down-li .select-icon {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 18px;
  right: 10px;
}
.choose-captain-mask .team-creation-rules-container {
  width: 100%;
  background: #1A439C;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 17px;
  padding-bottom: 20px;
  margin-top: 30px;
}
.choose-captain-mask .team-creation-rules-container .team-creation-rules-title {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
}
.choose-captain-mask .team-creation-rules-container .team-creation-rules-content {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 16px;
  margin-top: 18px;
}
.choose-captain-mask .team-creation-rules-btn {
  width: 320px;
  height: 45px;
  line-height: 45px;
  border-radius: 22.5px;
  font-size: 14px;
  font-weight: 500;
  color: #A46007;
  text-align: center;
  background: linear-gradient(180deg, #FFEB79 0%, #FFD244 100%);
  margin: 40px auto 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-captain-mask .team-creation-rules-btn:hover {
  opacity: 0.9;
}
.dividends-bg {
  background: #002265;
  position: relative;
}
.dividends-bg .dividends-component {
  width: 100%;
  margin-top: 15px;
}
.dividends-bg .dividends-component .banner {
  background: linear-gradient(360deg, #012678 0%, #07318A 100%);
  padding-top: 280px;
  position: relative;
}
.dividends-bg .dividends-component .banner .dividends-title {
  width: 140px;
  height: 22px;
  /*font-size: 16px;*/
  /*font-family: PingFangSC-Semibold, PingFang SC;*/
  font-weight: 600;
  /*color: #6090F8;*/
  line-height: 22px;
  text-align: center;
  position: absolute;
  top: 45px;
  left: 50%;
  margin-left: -70px;
  font-size: 28px;
  color: #00F4E4;
  text-align: center;
}
.dividends-bg .dividends-component .banner .dividends-time-main {
  width: 300px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 87px;
  left: 50%;
  margin-left: -153px;
}
.dividends-bg .dividends-component .banner .dividends-time-main .dividends-time-item {
  width: 38px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
.dividends-bg .dividends-component .banner .dividends-time-main .dividends-time-item .time-item {
  width: 100%;
  height: 40px;
  font-size: 28px;
  color: #00F4E4;
  text-align: center;
}
.dividends-bg .dividends-component .banner .dividends-time-main .dividends-time-item .time-item-con {
  width: 100%;
  height: 22px;
  font-size: 16px;
  color: #6090F8;
  line-height: 22px;
  text-align: center;
}
.dividends-bg .dividends-component .banner .dividends-time-main .dividends-symbol-item {
  width: 8px;
  height: 40px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #6090F8;
  line-height: 40px;
  margin-left: 34px;
  margin-right: 34px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main {
  width: 1100px;
  height: 152px;
  background: #11388C;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  padding-top: 31px;
  padding-bottom: 31px;
  position: absolute;
  bottom: -76px;
  left: 50%;
  margin-left: -550px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info {
  width: 33.33%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info.line {
  background: url(../../static/images/Dividends/line.svg) no-repeat right center;
  background-size: 1px 50px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info .total-dividends-icon {
  width: 72px;
  height: 72px;
  border-radius: 45px;
  margin-left: 35px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info .total-dividends-icon.left-icon {
  margin-left: 72px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info .total-dividends-icon.center-icon {
  margin-left: 63px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info .total-dividends-icon.right-icon {
  margin-left: 72px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info .dividends-info-main {
  margin-left: 20px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info .dividends-info-main .dividends-info-title {
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #6090F8;
  line-height: 20px;
  margin-top: 2px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info .dividends-info-main .dividends-info-con {
  height: 40px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 40px;
  margin-top: 8px;
}
.dividends-bg .dividends-component .banner .dividends-total-info-main .total-dividends-info .dividends-info-main .dividends-info-con .unit {
  font-size: 14px;
}
.dividends-bg .dividends-component .dividends-team-container {
  width: 1100px;
  margin: 58px auto 0;
  border-radius: 9px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 50px;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main {
  width: 340px;
  height: 404px;
  background: #11388C;
  padding-left: 26px;
  padding-right: 26px;
  margin-top: 40px;
  margin-right: 40px;
  border-radius: 20px;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main:nth-child(3n) {
  margin-right: 0;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .create-icon {
  width: 24px;
  height: 24px;
  display: block;
  margin: 153px auto 0;
  cursor: pointer;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .create-con {
  width: 100%;
  height: 28px;
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  line-height: 28px;
  text-align: center;
  margin-top: 18px;
  cursor: pointer;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .nft-portrait {
  width: 73px;
  height: 73px;
  margin: 24px auto 0;
  display: block;
  border-radius: 50%;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .card-gary {
  filter: grayscale(100%);
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .nft-name {
  height: 48px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
  width: 100%;
  margin-top: 15px;
  text-align: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #1453A4;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .attribute-group {
  width: 100%;
  height: 20px;
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .attribute-group .attribute-key {
  font-size: 14px;
  font-weight: 600;
  color: #6090F8;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .attribute-group .attribute-value-1 {
  font-size: 14px;
  color: #FFFFFF;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .attribute-group .attribute-value-2 {
  font-size: 14px;
  font-weight: 600;
  color: #006EB8;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .join-btn {
  width: 288px;
  height: 40px;
  line-height: 40px;
  background: linear-gradient(180deg, #FFEB79 0%, #FFD244 100%);
  border-radius: 20px;
  margin: 24px auto 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #A46007;
  cursor: pointer;
}
.dividends-bg .dividends-component .dividends-team-container .dividends-team-main .join-btn:hover {
  opacity: 0.5;
}
.mart41 {
  margin-top: 41px;
}
.mart22 {
  margin-top: 22px;
}
.rule-btn {
  position: fixed;
  top: 60%;
  right: 6%;
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 300;
}
.rule-btn:hover {
  opacity: 0.8;
}
.Dividends-Rewarding-Rules-mask {
  width: 580px;
  height: 630px;
  background: linear-gradient(159deg, #11388C 0%, #01246E 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -290px;
  margin-top: -290px;
  padding-left: 33px;
  padding-right: 33px;
}
.Dividends-Rewarding-Rules-mask .close-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.Dividends-Rewarding-Rules-mask .title {
  width: 100%;
  height: 33px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  line-height: 33px;
  margin-top: 30px;
  text-align: center;
}
.Dividends-Rewarding-Rules-mask .con {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #6090F8;
  line-height: 20px;
}
