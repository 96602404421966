html, body, #root, .App, .router {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}
div, ul, ol, p, span, i, li {
  padding: 0;
  margin: 0;
  
}

.router {
	background-color: #002265;
}
.loading-big {
	width: 50px;
	height: 50px;
	border: 6px solid#ccc;
	border-bottom: 6px solid #eee;
	border-radius:50%;
	animation:load 1.1s infinite linear;
	-webkit-animation:load 1.1s infinite linear;
	-moz-animation:load 1.1s infinite linear;
  -o-animation:load 1.1s infinite linear;
  z-index: 10;
}
.loading {
	width: 16px;
	height: 16px;
	border: 2px solid#fff;
	border-bottom: 2px solid #eee;
	border-radius:50%;
	animation:load 1.1s infinite linear;
	-webkit-animation:load 1.1s infinite linear;
	-moz-animation:load 1.1s infinite linear;
	-o-animation:load 1.1s infinite linear;
  	z-index: 300;
}
.loading-fb8126 {
	width: 16px;
	height: 16px;
	border: 2px solid #00F4E4;
	border-bottom: 2px solid #eee;
	border-radius:50%;
	animation:load 1.1s infinite linear;
	-webkit-animation:load 1.1s infinite linear;
	-moz-animation:load 1.1s infinite linear;
	-o-animation:load 1.1s infinite linear;
  	z-index: 300;
}

.loading-percentage {
	width: 50%;
	height: 50%;
	border: 3px solid#fff;
	border-bottom: 3px solid #eee;
	border-radius:50%;
	animation:load 1.1s infinite linear;
	-webkit-animation:load 1.1s infinite linear;
	-moz-animation:load 1.1s infinite linear;
	-o-animation:load 1.1s infinite linear;
  z-index: 300;
}

.loading-base {
	width: 16px;
	height: 16px;
	border: 3px solid#fff;
	border-bottom: 3px solid #eee;
	border-radius:50%;
	animation:load 1.1s infinite linear;
	-webkit-animation:load 1.1s infinite linear;
	-moz-animation:load 1.1s infinite linear;
	-o-animation:load 1.1s infinite linear;
  z-index: 300;
}
@keyframes load {
	from {
    transform:rotate(0deg);
    -ms-transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
    -ms-transform:rotate(360deg);
  }
}

.cursor-pointer {
	cursor: pointer;	
}

.close-btn {

    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 20px;
}

.mart10 {
	margin-top: 10px;
}
.mart15 {
	margin-top: 15px;
}
.mart20 {
	margin-top: 20px;
}
.mart30 {
	margin-top: 30px;
}

.text-indent2{
	text-indent: 2em;
}