.mining-container {
  width: 1100px;
  margin: auto;
}
.miningdetails-component {
  padding-bottom: 80px;
}
.miningdetails-component .top-compontent {
  width: 100%;
  height: 320px;
  position: relative;
}
.miningdetails-component .top-compontent .stake-info-container {
  width: 1100px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 10px;
  position: absolute;
  bottom: -60px;
  left: 50%;
  margin-left: -550px;
  box-shadow: 0px 0px 20px 0px rgba(229, 229, 229, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
}
.miningdetails-component .top-compontent .stake-info-container .stake-info-main {
  border-left: 1px solid #E6E6E6;
  width: 33.33%;
}
.miningdetails-component .top-compontent .stake-info-container .stake-info-main:nth-child(1) {
  border-left: none;
}
.miningdetails-component .top-compontent .stake-info-container .stake-info-main:nth-child(3) .stake-info-key {
  margin-left: 111px;
}
.miningdetails-component .top-compontent .stake-info-container .stake-info-main:nth-child(3) .stake-info-value {
  margin-left: 111px;
}
.miningdetails-component .top-compontent .stake-info-container .stake-info-main .stake-info-key {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-left: 121px;
}
.miningdetails-component .top-compontent .stake-info-container .stake-info-main .stake-info-value {
  width: 100%;
  height: 35px;
  font-size: 30px;
  font-weight: bold;
  color: #222222;
  line-height: 35px;
  margin-left: 121px;
  margin-top: 8px;
}
.miningdetails-component .top-compontent .stake-info-container .stake-info-main .stake-info-value.fc26BFA2 {
  color: #26BFA2;
}
.miningdetails-component .stake-component {
  width: 100%;
  margin-top: 81px;
  height: 578px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(229, 229, 229, 0.5);
  border-radius: 20px;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
}
.miningdetails-component .stake-component.mynft-component {
  height: 359px;
}
.miningdetails-component .stake-component .title {
  width: 100%;
  height: 33px;
  font-size: 24px;
  font-weight: 600;
  color: #222222;
  line-height: 33px;
  text-align: left;
  margin-top: 26px;
}
.miningdetails-component .stake-component .stake-info-container {
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6E6E6;
}
.miningdetails-component .stake-component .stake-info-container .stake-info-main {
  height: 42px;
  background: #F8F8F8;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.miningdetails-component .stake-component .stake-info-container .stake-info-main .stake-info-li {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-left: 60px;
  padding-left: 10px;
  padding-right: 10px;
}
.miningdetails-component .stake-component .stake-info-container .stake-info-main .stake-info-li:nth-child(1) {
  margin-left: 0;
}
.miningdetails-component .stake-component .stake-info-container .stake-operation-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.miningdetails-component .stake-component .stake-info-container .stake-operation-btn .harvest {
  width: 120px;
  height: 42px;
  line-height: 42px;
  border-radius: 21px;
  border: 2px solid #D3D3D3;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.miningdetails-component .stake-component .stake-info-container .stake-operation-btn .harvest-all {
  width: 120px;
  height: 42px;
  line-height: 42px;
  background: #26BFA2;
  border-radius: 21px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.miningdetails-component .stake-component .stake-list-container {
  width: 100%;
  height: 444px;
  overflow-y: auto;
  padding-bottom: 36px;
}
.miningdetails-component .stake-component .stake-list-container.mynft-list-container {
  height: 300px;
}
.miningdetails-component .stake-component .stake-list-container .stake-li {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 36px;
}
.miningdetails-component .stake-component .stake-list-container .stake-li .stake-icon {
  width: 88px;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.miningdetails-component .stake-component .stake-list-container .stake-li .stake-icon img {
  display: block;
  width: 100%;
}
.miningdetails-component .stake-component .stake-list-container .stake-li .stake-info-group {
  width: 100%;
  height: 102px;
  margin-left: 20px;
}
.miningdetails-component .stake-component .stake-list-container .stake-li .stake-info-group .stake-info-title {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
  line-height: 20px;
}
.miningdetails-component .stake-component .stake-list-container .stake-li .stake-info-group .stake-amount {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999990;
  line-height: 20px;
  margin-top: 30px;
}
.miningdetails-component .stake-component .stake-list-container .stake-li .stake-info-group .stake-rate {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999990;
  line-height: 20px;
  margin-top: 10px;
}
.miningdetails-component .stake-component .stake-list-container .stake-li .btn {
  width: 120px;
  height: 42px;
  line-height: 42px;
  background: #26BFA2;
  border-radius: 21px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fc26BFA2 {
  color: #26BFA2;
}
.fcFF7E00 {
  color: #FF7E00;
}
.fcF65830 {
  color: #F65830;
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.mask .mask-container {
  width: 424px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -212px;
  padding-left: 34px;
  padding-right: 34px;
}
.mask .mask-container.sale-mask-container {
  height: 377px;
  margin-top: -188px;
}
.mask .mask-container.send-mask-container {
  height: 317px;
  margin-top: -158px;
}
.mask .mask-container .centent {
  width: 100%;
  font-size: 14px;
  color: #000000;
  line-height: 14px;
  text-align: left;
}
.mask .mask-container .mask-input-container {
  width: 100%;
  height: 45px;
  border: 1px solid #000;
  margin-top: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask .mask-container .mask-input-container .mask-input {
  width: 100%;
  height: 43px;
  line-height: 43px;
  text-indent: 14px;
  font-size: 14px;
  color: #999999;
  border: none;
  outline: none;
  padding: 0;
}
.mask .mask-container .mask-input-container .mask-input.isWarming {
  border: 1px solid #ff0000;
}
.mask .mask-container .mask-input-container .unit {
  width: 30px;
  height: 14px;
  font-size: 14px;
  color: #000000;
  line-height: 14px;
  position: absolute;
  top: 14px;
  right: 10px;
}
.mask .mask-container .mask-input-container .max-btn {
  width: 52px;
  height: 28px;
  line-height: 28px;
  background: #ECF0F3;
  position: absolute;
  top: 9px;
  right: 14px;
  text-align: center;
  font-size: 14px;
  color: #2BA8FF;
  cursor: pointer;
}
.mask .mask-container .confirm-btn {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: #2BA8FF;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask .mask-container .confirm-btn:hover {
  opacity: 0.5;
}
.empty-mining-list {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #999999;
}
.empty-mining-list .jump-cast-router {
  cursor: pointer;
}
.empty-mining-list .jump-cast-router:hover {
  opacity: 0.5;
}
.mart40 {
  margin-top: 40px!important;
}
