.casting-component {
  width: 100%;
  min-height: 100%;
  position: relative;
}
.casting-success-tips {
  width: 447px;
  height: 471px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px 0px #215554, 0px 2px 0px 0px rgba(255, 255, 255, 0.5), 2px 0px 0px 0px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -223px;
  margin-top: -236px;
  z-index: 150;
}
.casting-success-tips .close-btn {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.casting-success-tips .title {
  width: 100%;
  height: 24px;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 24px;
  margin-top: 53px;
  text-align: center;
}
.casting-success-tips .nft-pic {
  width: 202px;
  height: 202px;
  display: block;
  margin: 28px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.casting-success-tips .nft-pic img {
  display: block;
  width: 100%;
}
.casting-success-tips .ok-btn {
  width: 240px;
  height: 50px;
  background: #FFD86E;
  border-radius: 4px;
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.casting-success-tips .router-nft {
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #4996BD;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
.casting-nft-container {
  width: 744px;
  height: 548px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -372px;
  margin-top: -274px;
}
.casting-nft-container .close-btn {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.casting-nft-container .back-btn {
  position: absolute;
  top: 24px;
  left: 24px;
  cursor: pointer;
}
.casting-nft-container .swiper-compontent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.casting-nft-container .swiper-compontent .swiper-compontent-main {
  width: 100%;
}
.casting-nft-container .title {
  width: 100%;
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  line-height: 28px;
  margin-top: 30px;
  text-align: center;
}
.casting-nft-container .swiper-wrapper {
  width: 566px;
  margin: 0 auto;
}
.casting-nft-container .swiper-wrapper .swiper-slide {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main {
  width: 270px;
  height: 80px;
  background: #F8F8F8;
  border-radius: 8px;
  margin-top: 20px;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main:hover {
  background: #FFD86E;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main.active {
  background: #FFD86E;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main:nth-child(even) {
  margin-left: 26px;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main .portrait {
  width: 58px;
  height: 58px;
  background: #FFFFFF;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main .portrait img {
  width: 100%;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main .nft-main-info {
  margin-left: 11px;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main .nft-main-info .nft-name {
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  color: #000;
}
.casting-nft-container .swiper-wrapper .swiper-slide .nft-main .nft-main-info .fee {
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #999990;
  line-height: 14px;
  margin-top: 10px;
}
.casting-nft-container .casting-NFT-details {
  width: 524px;
  height: 219px;
  background: #F8F8F8;
  border-radius: 10px;
  margin: 27px auto 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.casting-nft-container .casting-NFT-details .decompose-btn {
  width: 59px;
  height: 45px;
  background: #FFD86E;
  border-radius: 100px 0px 0px 100px;
  position: absolute;
  right: 0;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.casting-nft-container .casting-NFT-details .decompose-btn img {
  width: 80%;
  margin-left: 10px;
}
.casting-nft-container .casting-NFT-details .casting-details-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 20px;
  padding-top: 20px;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-details-portrait {
  width: 92px;
  height: 92px;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-details-portrait img {
  width: 100%;
  height: 100%;
  display: block;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-info-container {
  margin-left: 14px;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-info-container .casting-name {
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 18px;
  font-weight: bold;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-info-container .casting-level {
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 9px;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-info-container .casting-level .level-display {
  width: 22px;
  height: 22px;
  background: #4996BD;
  border-radius: 2px;
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-info-container .casting-level .star-com {
  width: 14px;
  height: 14px;
  background: url(../../static/images/casting/star.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-info-container .casting-level .star-com:nth-child(1) {
  margin-left: 6px;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-info-container .casting-level .star-com img {
  width: 100%;
  display: block;
}
.casting-nft-container .casting-NFT-details .casting-details-info .casting-info-container .casting-fee {
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #666660;
  line-height: 14px;
  margin-top: 22px;
}
.casting-nft-container .casting-NFT-details .nft-info {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 34px;
}
.casting-nft-container .casting-NFT-details .nft-info .info-content {
  margin-right: 28px;
}
.casting-nft-container .casting-NFT-details .nft-info .buy-btn {
  width: 80px;
  height: 28px;
  line-height: 28px;
  border-radius: 4px;
  border: 1px solid #4996BD;
  font-size: 12px;
  font-weight: 400;
  color: #4996BD;
  text-align: center;
  margin-right: 16px;
  cursor: pointer;
}
.casting-nft-container .casting-NFT-details .nft-info .buy-btn:hover {
  background-color: #4996BD;
  color: #fff;
}
.casting-nft-container .balance-bar {
  width: 524px;
  height: 32px;
  line-height: 32px;
  margin: 13px auto 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.casting-nft-container .input-value-main {
  width: 524px;
  margin: 0 auto;
  height: 60px;
  line-height: 60px;
  border: 1px solid #D8D8D8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.casting-nft-container .input-value-main .drop-down-container {
  width: 114px;
  height: 60px;
  line-height: 60px;
  border-right: 1px solid #D8D8D8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.casting-nft-container .input-value-main .drop-down-container .drop-down-display {
  margin-left: 17px;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}
.casting-nft-container .input-value-main .drop-down-container .drop-icon {
  position: absolute;
  right: 17px;
  top: 46%;
}
.casting-nft-container .input-value-main .drop-down-container .drop-down-list {
  width: 114px;
  max-height: 90px;
  border-left: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  position: absolute;
  top: 60px;
  left: 0px;
  overflow-y: auto;
  background-color: #fff;
}
.casting-nft-container .input-value-main .drop-down-container .drop-down-list .drop-down-li {
  width: 100%;
  height: 45px;
  line-height: 45px;
  border-top: 1px solid #D8D8D8;
  font-size: 12px;
  color: #333333;
  text-indent: 17px;
  cursor: pointer;
}
.casting-nft-container .input-value-main .drop-down-container .drop-down-list .drop-down-li:hover {
  background-color: #D8D8D8;
}
.casting-nft-container .input-value-main .drop-down-container .drop-down-list .drop-down-li:nth-child(1) {
  border-top: none;
}
.casting-nft-container .input-value-main .par-value-key {
  width: 139px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.casting-nft-container .input-value-main .par-value-input {
  width: 269px;
  height: 56px;
  line-height: 56px;
  color: #666666;
  font-size: 14px;
  border: none;
  outline: none;
}
.casting-nft-container .casting-confirm-btn {
  margin: 54px auto 0;
  width: 312px;
  height: 60px;
  line-height: 60px;
  background-color: #FFD86E;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  border-radius: 8px;
  cursor: pointer;
}
.casting-nft-container .btn-bar {
  width: 524px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.casting-nft-container .btn-bar.confirm-btn-bar {
  justify-content: center;
}
.casting-nft-container .btn-bar.confirm-btn-bar .confirm-btn {
  width: 312px;
  height: 60px;
  line-height: 60px;
  margin-top: 22px;
  background-color: #FFD86E;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  border-radius: 8px;
  cursor: pointer;
}
.casting-nft-container .btn-bar.confirm-btn-bar .confirm-btn:hover {
  opacity: 0.5;
}
.casting-nft-container .btn-bar.approve-btn-bar {
  justify-content: center;
}
.casting-nft-container .btn-bar.approve-btn-bar .approve-btn {
  width: 251px;
  height: 60px;
  line-height: 60px;
  background: #FFD86E;
  border-radius: 4px;
  margin-top: 22px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  cursor: pointer;
}
.casting-nft-container .btn-bar.approve-btn-bar .approve-btn.disable-btn {
  width: 252px;
  height: 60px;
  background: #C7C8C8;
  border-radius: 4px;
  color: #fff;
}
.casting-top-main {
  width: 100%;
  margin-top: 34px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px 0px #DBDBDB, 0px -1px 0px 0px #DBDBDB;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.casting-top-main .casting-btn {
  width: 137px;
  height: 38px;
  line-height: 38px;
  background: #4996BD;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
}
.casting-top-main .casting-btn:hover {
  opacity: 0.5;
}
.table-compontent {
  width: 100%;
  margin-top: 8px;
}
.table-compontent thead tr td {
  height: 60px;
  background: #F8F8F8;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  line-height: 60px;
}
.table-compontent thead tr td:nth-child(1) {
  width: 25%;
  padding-left: 20px;
}
.table-compontent thead tr td:nth-child(2) {
  width: 25%;
}
.table-compontent thead tr td:nth-child(3) {
  width: 30%;
}
.table-compontent thead tr td:nth-child(4) {
  width: 20%;
}
.table-compontent tbody tr td {
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
}
.table-compontent tbody tr td .icon {
  width: 26px;
  height: 26px;
  margin-right: 12px;
}
.table-compontent tbody tr td:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}
.table-compontent tbody tr:nth-child(even) {
  background: #F8F8F8;
}
