.mynft-info-container {
  width: 100%;
  height: 150px;
  margin: 30px auto 0;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
}
.mynft-info-container .mynft-info-main {
  margin-right: 254px;
}
.mynft-info-container .mynft-info-main .tit {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.mynft-info-container .mynft-info-main .con {
  height: 33px;
  font-size: 24px;
  font-weight: 600;
  color: #F5A623;
  line-height: 33px;
  margin-top: 16px;
}
.mynft-list-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.mynft-list-container .empty-list {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.mynft-list-container .empty-list .empty-list-icon {
  width: 190px;
  height: 185px;
  margin: 0 auto;
  display: block;
}
.mynft-list-container .empty-list .empty-list-text {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #BEBEBE;
  line-height: 20px;
  margin-top: 20px;
  text-align: center;
}
.mynft-list-container .mynft-list-main {
  width: 23.1%;
  margin-right: 2.5%;
  background-color: #fff;
  margin-top: 30px;
  height: 386px;
  position: relative;
  cursor: pointer;
}
.mynft-list-container .mynft-list-main:nth-child(4n) {
  margin-right: 0;
}
.mynft-list-container .mynft-list-main .nft-pic {
  width: 100%;
  height: 275.23px;
}
.mynft-list-container .mynft-list-main .nft-pic img {
  width: 100%;
  display: block;
}
.mynft-list-container .mynft-list-main .mynft-attr-main {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 26px;
}
.mynft-list-container .mynft-list-main .mynft-attr-main .title {
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  line-height: 14px;
  margin-top: 15px;
}
.mynft-list-container .mynft-list-main .mynft-attr-main .attr {
  width: 100%;
  height: 14px;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
}
.mart18 {
  margin-top: 18px;
}
.mart10 {
  margin-top: 10px;
}
