@font-face {
  font-family: 'custom-font-1';
  src: url('../../../static/font/Courier.ttf');
}
.paging-bar {
  width: 100%;
  height: 58px;
  background: #FFFFFF;
  padding-right: 29px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.paging-bar .paging-min {
  width: 50px;
  height: 28px;
  line-height: 28px;
  background: #F4F4F4;
  font-size: 14px;
  color: #008503;
  text-align: center;
  margin-right: 6px;
  cursor: pointer;
}
.paging-bar .previous-page {
  width: 28px;
  height: 28px;
  background: #F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.paging-bar .previous-page:active .arrow-left {
  border-right: 7px solid #979797;
}
.paging-bar .previous-page .arrow-left {
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-right: 7px solid #008503;
}
.paging-bar .cur-page {
  width: 64px;
  height: 28px;
  line-height: 28px;
  background: #F4F4F4;
  text-align: center;
  margin-right: 6px;
  color: #666666;
  cursor: pointer;
}
.paging-bar .next-page {
  width: 28px;
  height: 28px;
  background: #F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  cursor: pointer;
}
.paging-bar .next-page:active .arrow-right {
  border-left: 7px solid #979797;
}
.paging-bar .next-page .arrow-right {
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  border-left: 7px solid #008503;
}
.paging-bar .paging-max {
  width: 50px;
  height: 28px;
  line-height: 28px;
  background: #F4F4F4;
  font-size: 14px;
  color: #008503;
  text-align: center;
  margin-right: 6px;
  cursor: pointer;
}
